<template>
  <div>
    <button @click="jumpTool('/taiko')">太鼓分数系统</button>
  </div>
</template>

<script>
export default {
  methods: {
    jumpTool(url) {
      var currentUrl = window.location.href;
      window.location.href = currentUrl.split("/tool")[0] + url;
    },
  },
};
</script>
